import L from 'leaflet';

let map;
let markers = [];

export function initTpMap() {

    // Get treeplanting data from the Django template
    const treePlantingData = JSON.parse(document.getElementById('tree-planting-data').textContent);

    // Initialize map without setting view initially
    map = L.map('tpMap', {
        zoomControl: false,
    });

    // Add OpenStreetMap tiles
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);


    // Add tree planting markers
    const schoolIcon = L.icon({
        iconUrl: '/static/img/map_icons/map_pin.png',
        shadowUrl: '/static/img/map_icons/map_pin_shadow.png',
        iconSize: [20, 50],
        shadowSize: [50, 50],
        iconAnchor: [10, 50],
        shadowAnchor: [15, 52],
        popupAnchor: [0, -50]
    });

    const treeIcon = L.icon({
        iconUrl: '/static/img/map_icons/map_pin_tree.png',
        shadowUrl: '/static/img/map_icons/map_pin_tree_shadow.png',
        iconSize: [31.25, 50],
        shadowSize: [50, 50],
        iconAnchor: [10, 50],
        shadowAnchor: [9, 51],
        popupAnchor: [5.5, -50]
    });

    const schoolLatLng = L.latLng(51.4363, 14.252765);
    const closeDistanceThreshold = 100; // Define a threshold distance in meters

    // Initialize tree count close to school and plantings not shown on map
    let treesNearSchool = 0;
    let treesNotShown = 0;

    // Add school marker
    const schoolMarker = L.marker(schoolLatLng,
        {
            icon: schoolIcon,
            riseOnHover: true,
            title: 'Léon-Foucault-Gymnasium Hoyerswerda',
            alt: 'Léon-Foucault-Gymnasium Hoyerswerda',
            riseOffset: 250,
        }).addTo(map);
    markers.push(schoolMarker);

    // Add tree planting data to map and count trees near school
    treePlantingData.forEach(treePlanting => {
        const treeLatLng = L.latLng(treePlanting.tp_lat, treePlanting.tp_lon);
        if (treePlanting.show_on_map) {
            if (treeLatLng.distanceTo(schoolLatLng) <= closeDistanceThreshold) {
                // Increment the tree count if the tree is too close to the school
                treesNearSchool += treePlanting.number_of_trees;
            } else {
                const marker = L.marker(treeLatLng,
                    {
                        icon: treeIcon,
                        riseOnHover: true,
                        title: treePlanting.location_name,
                        alt: treePlanting.location_name,
                        riseOffset: 250,
                    }).addTo(map)
                    .bindPopup(`<b>${treePlanting.location_name}</b><br>Gepflanzte Bäume: ${treePlanting.number_of_trees}`);
                markers.push(marker);
            }
        } else {
            treesNotShown += treePlanting.number_of_trees;
        }
    });

    // Update the school marker popup to include the trees near the school if greater than 0
    let schoolMarkerPopup = "<b>Léon-Foucault-Gymnasium Hoyerswerda</b>";

    if (treesNearSchool > 0) {
        schoolMarkerPopup = schoolMarkerPopup.concat('<br><br>', `${treesNearSchool} Bäume wurden in der Nähe der Schule oder auf dem Schulhof gepflanzt.`);
    }

    if (treesNotShown > 0) {
        schoolMarkerPopup = schoolMarkerPopup.concat('<br><br>', `Baumpflanzungen für insgesamt ${treesNotShown} Bäume werden nicht auf der Karte angezeigt.`);
    }

    schoolMarker.bindPopup(schoolMarkerPopup);

    // Create a LatLngBounds object to contain the coordinates of all markers
    const bounds = new L.LatLngBounds(markers.map(marker => marker.getLatLng()));

    // Fit the map view to the bounds with some padding
    map.fitBounds(bounds, {padding: [50, 50]});


    // Custom controls
    var customControl = L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: function (map) {
            var container = L.DomUtil.create('div', '');

            var button = L.DomUtil.create('button', 'btn btn-sm btn-light', container);
            button.innerHTML = '<i class="bi-arrow-clockwise"></i>';
            button.style = 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); border-radius: 5px;';

            L.DomEvent.disableClickPropagation(button);

            L.DomEvent.on(button, 'click', function () {
                map.fitBounds(bounds, {padding: [50, 50]});
            });

            return container;
        }
    });

    var customZoomControl = L.Control.extend({
        options: {
            position: 'topleft'
        },

        onAdd: function (map) {
            var controlContainer = L.DomUtil.create('div');

            // Zoom-In Button
            var zoomInButton = L.DomUtil.create('button', 'btn btn-sm btn-light d-block mb-1', controlContainer);
            zoomInButton.innerHTML = '<i class="bi bi-plus-lg"></i>';
            zoomInButton.style = 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); border-radius: 5px;';
            zoomInButton.onclick = function () {
                map.zoomIn();
            };

            // Zoom-Out Button
            var zoomOutButton = L.DomUtil.create('button', 'btn btn-sm btn-light d-block', controlContainer);
            zoomOutButton.innerHTML = '<i class="bi bi-dash-lg"></i>';
            zoomOutButton.style = 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); border-radius: 5px;';
            zoomOutButton.onclick = function () {
                map.zoomOut();
            };

            L.DomEvent.disableClickPropagation(controlContainer);

            return controlContainer;
        }
    });

    // Add custom controls to map
    map.addControl(new customControl());
    map.addControl(new customZoomControl());
}

export function zoomToTreePlanting(lat, lon) {
    lat = parseFloat(lat.replace(',', '.'));
    lon = parseFloat(lon.replace(',', '.'));

    if (isNaN(lat) || isNaN(lon)) {
        console.error('Ungültige Koordinaten:', { lat, lon });
        return;
    }

    let location = L.latLng(lat, lon);

    document.getElementById('tpMap').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });

    console.log("Zooming to:", location);
    map.flyTo(location, 14);

    markers.forEach(marker => {
        if (marker.getLatLng().equals(location)) {
            map.once('zoomend', function () {
                marker.openPopup();
            });
        }
    });
}

import ApexCharts from 'apexcharts';

export function initCO2Chart() {
    console.log('CO2 Chart wird initialisiert...');

    const co2Data = JSON.parse(document.getElementById('co2-data').textContent);

    // Daten für das Diagramm vorbereiten
    const seriesData = co2Data.map(data => ({
        x: data.years,
        y: data.co2_long_term_concentration
    }));

    // Manuelle Ticks in 100-Jahres-Intervallen vorbereiten
    const minYear = 1000;
    const maxYear = Math.max(...co2Data.map(data => data.years));
    const tickYears = [];
    for (let year = minYear; year <= maxYear; year += 100) {
        tickYears.push(year);
    }

    // Funktion zur Festlegung des offsetY-Wertes basierend auf der Fensterbreite
    function setYOffset() {
        if (window.innerWidth >= 991) {
            return -25;
        } else {
            return 5;
        }
    }

    // Funktion zur Ermittlung der Diagrammoptionen
    function getChartOptions() {
        return {
            chart: {
                type: 'line',
                height: 350,
                width: '100%',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: false,
                }
            },
            series: [{
                name: 'CO₂ Konzentration',
                data: seriesData
            }],
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            colors: ['#00698999'],
            xaxis: {
                type: 'numeric',
                title: {
                    text: 'Jahr',
                    offsetY: setYOffset() // offsetY-Wert dynamisch setzen
                },
                categories: tickYears,
                tickAmount: tickYears.length,
                min: minYear,
                max: maxYear,
                labels: {
                    rotate: -45,
                    maxHeight: 100
                }
            },
            yaxis: {
                title: {
                    text: 'CO₂ Konzentration (ppm)',
                    offsetX: 0
                },
                labels: {
                    formatter: function (value) {
                        return `${value}`;
                    }
                }
            },
            tooltip: {
                enabled: true,
                x: {
                    show: false,
                    formatter: function (val) {
                        return '<strong>Jahr</strong>:' + val;
                    }
                },
                y: {
                    formatter: function (val) {
                        return val + " ppm";
                    }
                }
            }
        };
    }

    // Diagramm rendern
    const chart = new ApexCharts(document.querySelector("#co2Chart"), getChartOptions());
    chart.render();

    // Event-Listener für die Fenstergrößenänderung
    window.addEventListener('resize', function () {
        chart.updateOptions(getChartOptions());
    });

}